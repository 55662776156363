html {
  min-height: 100%;
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: none;
  touch-action: none;
  overflow: hidden;
  overscroll-behavior: none;
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}


#gamecanvas {
  position: relative;
  overflow: hidden;
}

span {
  color: #fff
}

* {
  font-family: 'Roboto', Arial, sans-serif;
}

.txtedit {
  background-color:#fff;
  resize: none
}

.playbutton:disabled {
  /* background-color: rgb(141, 141, 141,0.2) !important; */
  opacity: 0.2 !important;
}

/* ActionButton */
.actionbutton {
  color: #000 !important;
}

.actionbutton span {
  color: #000 !important;
}

.actionbutton:enabled:active,
.actionbutton:enabled:hover {
  color: #000 !important;
}

.actionbutton:disabled {
  color: rgb(141, 141, 141);
  /* background-color: rgb(141, 141, 141,0.2) !important; */
  opacity: 0.2;
}

.settingarea {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}

.settingarea_tab_inactive {
  background-color: #666666;
  color: #fff;
  border: none;
  outline: none;
}
.settingarea_tab_active {
  background-color: #000;
  color: #fff;
  border: none;
  outline: none;
}

a {
  color: '#fff'
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1b7a3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f0f0d8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #afaf9a;
}

button {
  border: 0;
  outline: 0;
}

.gameinput {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  outline: none;
  text-align: center;
}
.gameinput:focus {
  outline: none;
}
.gameinput::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

#gamespinner {
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 9; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.7); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: hidden;
}

.spinner {
  animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
      transform: rotate(0deg);
  }

  to {
      transform: rotate(359deg);
  }
}


#gamedialog {
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 60; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.7); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: hidden; /* Disable horizontal scroll */
}
#gamedialog_rotated {
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 60; /* Sit on top */
  left: 0;
  top: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: hidden; /* Disable horizontal scroll */
}


.rotation-wrapper-outer {
  display: table;
  width: 100%;
  height: 100%;
}

.rotation-wrapper-inner {
  padding: 50% 0;
  height: 0;
}

.div-rotated {
  display: block;
  transform-origin: top left;
  /* Note: for a CLOCKWISE rotation, use the commented-out
     transform instead of this one. */
  transform: rotate(-90deg) translate(-100%);
  /* transform: rotate(90deg) translate(0, -100%); */
  margin-top: -50%;
}


.bounce-top {
  -webkit-animation: bounce-top 0.9s both;
  animation: bounce-top 0.9s both;
}

.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-right2 {
	-webkit-animation: slide-in-right2 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right2 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
* Generated by Animista on 2020-10-15 18:43:0
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation bounce-top
* ----------------------------------------
*/
@-webkit-keyframes bounce-top {
  0% {
      -webkit-transform: translateY(-45px);
      transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 1;
  }

  24% {
      opacity: 1;
  }

  40% {
      -webkit-transform: translateY(-24px);
      transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
  }

  65% {
      -webkit-transform: translateY(-12px);
      transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
  }

  82% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
  }

  93% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
  }

  100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
  }
}

@keyframes bounce-top {
  0% {
      -webkit-transform: translateY(-45px);
      transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 1;
  }

  24% {
      opacity: 1;
  }

  40% {
      -webkit-transform: translateY(-24px);
      transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
  }

  65% {
      -webkit-transform: translateY(-12px);
      transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
  }

  82% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
  }

  93% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
  }

  100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-22 8:59:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
 @-webkit-keyframes slide-in-right2 {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right2 {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
